// @ts-ignore
/* eslint-disable */
import request from '@/request/request';

/** 物流公司列表 GET /base/api-v1/dict/list-delivery-company */
export async function listDeliveryCompany(options?: { [key: string]: any }) {
  return request<API.RListDictValueResponse>('/base/api-v1/dict/list-delivery-company', {
    method: 'GET',
    ...(options || {}),
  });
}
