// @ts-ignore
/* eslint-disable */
import request from '@/request/request';

/** 小程序订阅消息-添加模板 POST /admin/api-v1/merchant-applet/${param0}/add */
export async function add(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminAddParams,
  body: API.AddAppletMsgTemplateRequest,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/merchant-applet/${param0}/add`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 小程序订阅消息-刷新缓存 POST /admin/api-v1/merchant-applet/${param0}/flush-msg-template-cache */
export async function flushMsgTemplateCache(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminFlushMsgTemplateCacheParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/merchant-applet/${param0}/flush-msg-template-cache`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 小程序类目-可设置列表 GET /admin/api-v1/merchant-applet/${param0}/get-all-categories */
export async function getAllCategories(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminGetAllCategoriesParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RMapStringObject>(
    `/admin/api-v1/merchant-applet/${param0}/get-all-categories`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 小程序搜索状态-获取 GET /admin/api-v1/merchant-applet/${param0}/get-applet-search-status */
export async function getAppletSearchStatus(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminGetAppletSearchStatusParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RWxOpenMaSearchStatusResult>(
    `/admin/api-v1/merchant-applet/${param0}/get-applet-search-status`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 小程序类目-获取已设置的 GET /admin/api-v1/merchant-applet/${param0}/get-category */
export async function getCategories(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminGetCategoriesParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RListCategoriesBean>(`/admin/api-v1/merchant-applet/${param0}/get-category`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 小程序信息-获取商户的 GET /admin/api-v1/merchant-applet/${param0}/get-details */
export async function getDetails(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminGetDetailsParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RGetAppletDetailsResponse>(
    `/admin/api-v1/merchant-applet/${param0}/get-details`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 小程序代码-审核状态 GET /admin/api-v1/merchant-applet/${param0}/get-last-audit-status */
export async function getLastAuditStatus(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminGetLastAuditStatusParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RWxOpenMaQueryAuditResult>(
    `/admin/api-v1/merchant-applet/${param0}/get-last-audit-status`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 小程序服务器域名-列表 GET /admin/api-v1/merchant-applet/${param0}/get-server-domain */
export async function getServerDomain(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminGetServerDomainParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RWxOpenMaDomainResult>(
    `/admin/api-v1/merchant-applet/${param0}/get-server-domain`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 小程序代码-版本信息 GET /admin/api-v1/merchant-applet/${param0}/get-version-info */
export async function getVersionInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminGetVersionInfoParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RWxOpenVersioninfoResult>(
    `/admin/api-v1/merchant-applet/${param0}/get-version-info`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 小程序业务域名-列表 GET /admin/api-v1/merchant-applet/${param0}/get-webview-domain */
export async function getWebviewDomain(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminGetWebviewDomainParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RWxOpenMaWebDomainResult>(
    `/admin/api-v1/merchant-applet/${param0}/get-webview-domain`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 小程序-初始化信息 POST /admin/api-v1/merchant-applet/${param0}/init */
export async function initApplet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminInitAppletParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/merchant-applet/${param0}/init`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 小程序订阅消息-初始化默认模板 POST /admin/api-v1/merchant-applet/${param0}/init-default-template */
export async function initDefaultTemplate(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminInitDefaultTemplateParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/merchant-applet/${param0}/init-default-template`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 小程序服务器域名-初始化 POST /admin/api-v1/merchant-applet/${param0}/init-server-domain */
export async function initServerDomain(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminInitServerDomainParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/merchant-applet/${param0}/init-server-domain`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 小程序业务域名-初始化 POST /admin/api-v1/merchant-applet/${param0}/init-webview-domain */
export async function initWebviewDomain(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminInitWebviewDomainParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/merchant-applet/${param0}/init-webview-domain`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 小程序订阅消息-获取已配置模板列表 GET /admin/api-v1/merchant-applet/${param0}/list-subscribe-template */
export async function list(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminListParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RListTemplateInfo>(
    `/admin/api-v1/merchant-applet/${param0}/list-subscribe-template`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 小程序订阅消息-获取关键词列表 GET /admin/api-v1/merchant-applet/${param0}/list-template-key-words/${param1} */
export async function listPubTemplateKeyWordsById(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminListPubTemplateKeyWordsByIdParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, templateId: param1, ...queryParams } = params;
  return request<API.RListPubTemplateKeyword>(
    `/admin/api-v1/merchant-applet/${param0}/list-template-key-words/${param1}`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 小程序体验用户-列表 GET /admin/api-v1/merchant-applet/${param0}/list-tester */
export async function listTester(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminListTesterParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RListWxOpenMaMember>(`/admin/api-v1/merchant-applet/${param0}/list-tester`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 小程序订阅消息-所属类目下的公共模板 GET /admin/api-v1/merchant-applet/${param0}/page-pub-template-title */
export async function pagePubTemplateTitle(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminPagePubTemplateTitleParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RPubTemplateTitleListResult>(
    `/admin/api-v1/merchant-applet/${param0}/page-pub-template-title`,
    {
      method: 'GET',
      params: {
        ...queryParams,
      },
      ...(options || {}),
    },
  );
}

/** 公众号-获取关联的小程序 POST /admin/api-v1/merchant-applet/${param0}/register */
export async function getMpLinkApplet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminGetMpLinkAppletParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RWxAmpLinkResult>(`/admin/api-v1/merchant-applet/${param0}/register`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 小程序-发布审核通过的 POST /admin/api-v1/merchant-applet/${param0}/release-audit */
export async function releaseAudited(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminReleaseAuditedParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RWxOpenResult>(`/admin/api-v1/merchant-applet/${param0}/release-audit`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 小程序体验版-发布-最新模板 POST /admin/api-v1/merchant-applet/${param0}/release-test-from-new-Template */
export async function releaseTestFromNewTemplate(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminReleaseTestFromNewTemplateParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RAppletTrialInfo>(
    `/admin/api-v1/merchant-applet/${param0}/release-test-from-new-Template`,
    {
      method: 'POST',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 小程序名称-审核状态 返回参数：https://developers.weixin.qq.com/doc/oplatform/openApi/OpenApiDoc/miniprogram-management/basic-info-management/getNickNameStatus.html GET /admin/api-v1/merchant-applet/${param0}/search-applet-name-audit-staus */
export async function searchAppletNameAuditStatus(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminSearchAppletNameAuditStatusParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RWxFastMaQueryNicknameStatusResult>(
    `/admin/api-v1/merchant-applet/${param0}/search-applet-name-audit-staus`,
    {
      method: 'GET',
      params: {
        ...queryParams,
      },
      ...(options || {}),
    },
  );
}

/** 小程序名称-设置 返回参数：https://developers.weixin.qq.com/doc/oplatform/openApi/OpenApiDoc/miniprogram-management/basic-info-management/setNickName.html POST /admin/api-v1/merchant-applet/${param0}/set-applet-name */
export async function setAppletName(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminSetAppletNameParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RWxFastMaSetNickameResult>(
    `/admin/api-v1/merchant-applet/${param0}/set-applet-name`,
    {
      method: 'POST',
      params: {
        ...queryParams,
        idCardFile: undefined,
        ...queryParams['idCardFile'],
        licenseFile: undefined,
        ...queryParams['licenseFile'],
        otherStuff1File: undefined,
        ...queryParams['otherStuff1File'],
        otherStuff2File: undefined,
        ...queryParams['otherStuff2File'],
      },
      ...(options || {}),
    },
  );
}

/** 小程序-设置用户隐私保护协议 POST /admin/api-v1/merchant-applet/${param0}/set-privacy-agreement */
export async function setPrivacyAgreement(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminSetPrivacyAgreementParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/merchant-applet/${param0}/set-privacy-agreement`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 设置发货消息跳转路径设置接口 POST /admin/api-v1/merchant-applet/${param0}/shipping/set-msg-jump-path */
export async function setShippingMsgJumpPath(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminSetShippingMsgJumpPathParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/merchant-applet/${param0}/shipping/set-msg-jump-path`, {
    method: 'POST',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 小程序信息-同步 POST /admin/api-v1/merchant-applet/${param0}/sync */
export async function syncBasicInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminSyncBasicInfoParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RWxFastMaAccountBasicInfoResult>(
    `/admin/api-v1/merchant-applet/${param0}/sync`,
    {
      method: 'POST',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 小程序头像-修改 POST /admin/api-v1/merchant-applet/${param0}/update-head-image */
export async function updateAppletHeadImage(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminUpdateAppletHeadImageParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/merchant-applet/${param0}/update-head-image`, {
    method: 'POST',
    params: {
      ...queryParams,
      file: undefined,
      ...queryParams['file'],
    },
    ...(options || {}),
  });
}

/** 小程序提审素材-上传 POST /admin/api-v1/merchant-applet/${param0}/upload-media */
export async function uploadMedia(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminUploadMediaParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RWxMaAuditMediaUploadResult>(
    `/admin/api-v1/merchant-applet/${param0}/upload-media`,
    {
      method: 'POST',
      params: {
        ...queryParams,
        file: undefined,
        ...queryParams['file'],
      },
      ...(options || {}),
    },
  );
}

/** 小程序类目-添加 GET /admin/api-v1/merchant-applet/add-category */
export async function addCategory(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminAddCategoryParams,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/merchant-applet/add-category', {
    method: 'GET',
    params: {
      ...params,
      request: undefined,
      ...params['request'],
    },
    ...(options || {}),
  });
}

/** 小程序体验版-全商户发布-最新模板 POST /admin/api-v1/merchant-applet/all-merchant/release-test-from-new-Template */
export async function releaseTestFromNewTemplateForAllMerchant(options?: { [key: string]: any }) {
  return request<API.RReleaseTestAppletForAllMerchantResponse>(
    '/admin/api-v1/merchant-applet/all-merchant/release-test-from-new-Template',
    {
      method: 'POST',
      ...(options || {}),
    },
  );
}

/** 小程序体验用户-【绑定|解除】 POST /admin/api-v1/merchant-applet/bind-tester */
export async function bindTester(
  body: API.BindAppletTesterRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/merchant-applet/bind-tester', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 小程序名称-检测 返回参数：https://developers.weixin.qq.com/doc/oplatform/openApi/OpenApiDoc/miniprogram-management/basic-info-management/checkNickName.html GET /admin/api-v1/merchant-applet/check-applet-name */
export async function checkAppletName(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletAdminCheckAppletNameParams,
  options?: { [key: string]: any },
) {
  return request<API.RWxFastMaCheckNickameResult>(
    '/admin/api-v1/merchant-applet/check-applet-name',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 小程序订阅消息-删除模板 DELETE /admin/api-v1/merchant-applet/delete */
export async function deleteUsingDELETE(
  body: API.DeleteSubscribeTemplateRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/merchant-applet/delete', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 小程序类目-删除 DELETE /admin/api-v1/merchant-applet/delete-category */
export async function deleteCategory(
  body: API.DeleteAppletCategoryRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/merchant-applet/delete-category', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 小程序订阅消息-初始化默认模板【批量】 POST /admin/api-v1/merchant-applet/init-default-template */
export async function batchInitDefaultTemplate(body: number[], options?: { [key: string]: any }) {
  return request<API.RVoid>('/admin/api-v1/merchant-applet/init-default-template', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 小程序服务器域名-设置 POST /admin/api-v1/merchant-applet/modify-server-domain */
export async function modifyServerDomain(
  body: API.ModifyAppletServerDomainRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/merchant-applet/modify-server-domain', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 小程序业务域名-设置 POST /admin/api-v1/merchant-applet/modify-webview-domain */
export async function modifyWebviewDomain(
  body: API.ModifyWebViewDomainRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/merchant-applet/modify-webview-domain', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 小程序体验版-发布-指定模板 POST /admin/api-v1/merchant-applet/release-test */
export async function releaseTest(
  body: API.ReleaseTestAppletRequest,
  options?: { [key: string]: any },
) {
  return request<API.RReleaseTestAppletResponse>('/admin/api-v1/merchant-applet/release-test', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 小程序搜索状态-设置 POST /admin/api-v1/merchant-applet/set-applet-search-status */
export async function setAppletSearchStatus(
  body: API.SetAppletSearchStatusRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/merchant-applet/set-applet-search-status', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 小程序代码-提交审核 POST /admin/api-v1/merchant-applet/submit-audit */
export async function submitAudit(
  body: API.SubmitAppletAuditRequest,
  options?: { [key: string]: any },
) {
  return request<API.RWxOpenMaSubmitAuditResult>('/admin/api-v1/merchant-applet/submit-audit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 同步并拉取已授权的小程序帐号信息 POST /admin/api-v1/merchant-applet/sync-and-get-authorizer-applet */
export async function syncAndGetAuthorizerList(
  body: API.SyncAndGetAuthorizerAppletListRequest,
  options?: { [key: string]: any },
) {
  return request<API.RWxOpenAuthorizerListResult>(
    '/admin/api-v1/merchant-applet/sync-and-get-authorizer-applet',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}
