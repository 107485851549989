
import { RouteView } from "@/layouts";
import commodityDetails from "@/views/commodityManage/commodityDetails.vue"
import brandList from "@/views/brandManage/brandList.vue"
import addGoods from "@/views/cateManage/addGoods.vue"
import commodityList from "@/views/commodityManage/commodityList.vue"
export default {
  path: "/commodityManage",
  name: "",
  component: RouteView,
  meta: {
    title: "商品管理",
    isupperLevels:true

  },
  children: [
    {
      path: "/commodityManage/commodityCategory",
      name: "commodityCategory",
      component: brandList,
      meta: {
        title: "品牌列表",
        inMenu: false,
        isupperLevels:true
      },
    },
    {
      path: "/commodityManage/commodityList",
      name: "commodityList",
      component: commodityList,
      meta: {
        title: "商品列表",
        inMenu: false,
      },
    },
     {
      path: "/commodityManage/commodityDetails",
      name: "commodityDetails",
      component: commodityDetails,
      meta: {
        title: "商品详情",
        inMenu: false,
      },
    },
    {
      path: "/commodityManage/add",
      name: "commodityManageAddGoods",
      component: addGoods,
      meta: {
        title: "品牌新增商品",
        inMenu: false,
        isupperLevels:true
      },
    },
  ],
};
