// @ts-ignore
/* eslint-disable */
import request from '@/request/request';

/** 修改(品牌管理) PUT /admin/api-v1/goods-brand-dcs/${param0} */
export async function update(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsBrandDcsAdminUpdateParams,
  body: API.SaveBrandDcsRequest,
  options?: { [key: string]: any },
) {
  const { dcsBrandId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/goods-brand-dcs/${param0}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 上下架(品牌管理) PUT /admin/api-v1/goods-brand-dcs/${param0}/up-down */
export async function upDown(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsBrandDcsAdminUpDownParams,
  body: API.PutBrandUpDownDcsRequest,
  options?: { [key: string]: any },
) {
  const { dcsBrandId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/goods-brand-dcs/${param0}/up-down`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 新增(品牌管理) POST /admin/api-v1/goods-brand-dcs/add */
export async function add(body: API.SaveBrandDcsRequest, options?: { [key: string]: any }) {
  return request<API.RVoid>('/admin/api-v1/goods-brand-dcs/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 取消授权商户品牌(商户管理) POST /admin/api-v1/goods-brand-dcs/authorized-cancel/${param0} */
export async function authorizedCancel(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsBrandDcsAdminAuthorizedCancelParams,
  body: number[],
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/goods-brand-dcs/authorized-cancel/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 品牌列表[授权｜未授权](筛选框) GET /admin/api-v1/goods-brand-dcs/authorized-list */
export async function authorizedList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsBrandDcsAdminAuthorizedListParams,
  options?: { [key: string]: any },
) {
  return request<API.RCollectionBrandAuthorizedListResponse>(
    '/admin/api-v1/goods-brand-dcs/authorized-list',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 以授权品牌的商户列表(品牌管理) GET /admin/api-v1/goods-brand-dcs/authorized-merchant */
export async function authorizedMerchantPage(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsBrandDcsAdminAuthorizedMerchantPageParams,
  options?: { [key: string]: any },
) {
  return request<API.RIPageGoodsAuthorizedMerchantResponse>(
    '/admin/api-v1/goods-brand-dcs/authorized-merchant',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 已授权品牌分页列表 GET /admin/api-v1/goods-brand-dcs/authorized-page */
export async function authorizedBrandPage(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsBrandDcsAdminAuthorizedBrandPageParams,
  options?: { [key: string]: any },
) {
  return request<API.RIPageGoodsAuthorizedBrandResponse>(
    '/admin/api-v1/goods-brand-dcs/authorized-page',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 授权商户品牌(商户管理) POST /admin/api-v1/goods-brand-dcs/authorized/${param0} */
export async function authorizedMerchant(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsBrandDcsAdminAuthorizedMerchantParams,
  body: API.PutGoodsBrandAuthorizedDiscountRequest[],
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/goods-brand-dcs/authorized/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 列表(筛选框) GET /admin/api-v1/goods-brand-dcs/list */
export async function brands(options?: { [key: string]: any }) {
  return request<API.RCollectionBrandResponse>('/admin/api-v1/goods-brand-dcs/list', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 分页列表(品牌管理) GET /admin/api-v1/goods-brand-dcs/page */
export async function page(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsBrandDcsAdminPageParams,
  options?: { [key: string]: any },
) {
  return request<API.RIPageGoodsBrandPageResponse>('/admin/api-v1/goods-brand-dcs/page', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
