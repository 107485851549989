
import { RouteView } from "@/layouts";
import lnquiryCenterList from "@/views/inquiryCenter/inquiryCenter.vue";
import inquiryDetail from "@/views/inquiryCenter/inquiryDetail.vue";

export default {
  path: "/inquiryCenter",
  name: "",
  component: RouteView,
  meta: {
    title: "询单中心",
    isupperLevels:true
  },
  children: [
    {
      path: "/inquiryCenter/list",
      name: "inquiryCenterList",
      component: lnquiryCenterList,
      meta: {
        title: "询单列表",
        inMenu: false,
        isupperLevels:true
      },
    },
    {
        path: "/inquiryCenter/detail",
        name: "inquiryDetail",
        component: inquiryDetail,
        meta: {
          title: "询单详情",
          inMenu: false,
          isupperLevels:false
        },
      },
  ],
};
