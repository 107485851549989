// @ts-ignore
/* eslint-disable */
import request from '@/request/request';

/** 商戶小程序注册 POST /admin/api-v1/merchant-applet-register */
export async function register(
  body: API.RegisterMerchantAppletRequest,
  options?: { [key: string]: any },
) {
  return request<API.RMapStringObject>('/admin/api-v1/merchant-applet-register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 商戶小程序注册-取消 POST /admin/api-v1/merchant-applet-register/${param0}/cancel */
export async function cancel(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletRegisterAdminCancelParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RMapStringObject>(`/admin/api-v1/merchant-applet-register/${param0}/cancel`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 商戶小程序注册-当前阶段 GET /admin/api-v1/merchant-applet-register/${param0}/getStage */
export async function getStage(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAppletRegisterAdminGetStageParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RAppletRegisterStageResponse>(
    `/admin/api-v1/merchant-applet-register/${param0}/getStage`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}
