import { RouteView } from "@/layouts";

import systemSettings from "@/views/systemSettings/systemSettings.vue";
export default {
  path: "/system",
  name: "system",
  component: systemSettings,
  meta: {
    title: "系统设置",
    isupperLevels: true,
  },
  children: [],
};
