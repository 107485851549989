// import { RouteView } from "@/layouts";

import userManage from "@/views/userManage/userManage.vue";
export default {
  path: "/userManage",
  name: "userManage",
  component: userManage,
  meta: {
    title: "用户管理",
    isupperLevels: true,
    isAdmin:true
  },
  children:[]
};
