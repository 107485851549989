<template>
  <div class="index">
    <div class="index-content">
      <div class="index-content-return">
        <span style="cursor: pointer" @click="toback">返回列表</span>
      </div>
      <commodityInfo
        ref="refcommodityInfo"
        @emitGoodInfo="emitGoodInfo"
        @cancelEdit="initM"
        :merchantId="merchantId"
        :goodsId="goodsId"
        :action="action"
        :dcsBrandsInfo="dcsBrandsInfo"
      />
    </div>
  </div>
</template>

<script  setup>
import commodityInfo from "@/components/commodityInfo.vue";
import { useRouter, useRoute } from "vue-router";
import { ref, reactive, onBeforeMount, defineProps } from "vue";
import * as goodsMerchantAdmin from "@/services/api/goodsMerchantAdmin";
import * as goodsBrandDcsAdmin from "@/services/api/goodsBrandDcsAdmin";
import * as goodsDcsAdmin from "@/services/api/goodsDcsAdmin";
import { message } from "ant-design-vue";
const router = useRouter();
const route = useRoute();
const action = ref("");
const merchantId = ref("");
const goodsId = ref("");
const fromtype = ref("");
const brandId = ref("");
const dcsBrandsInfo = ref(false);
onBeforeMount(() => {
  initM();
});
const initM = () => {
  const {
    merchantId: MerchantId,
    action: Action,
    goodsId: GoodsId,
    type: From,
    brandId: BrandId,
  } = route.query;
  action.value = Action;
  merchantId.value = MerchantId;
  goodsId.value = GoodsId;
  fromtype.value = From;
  brandId.value = BrandId;
  if (From === "dcs") {
    dcsBrandsInfo.value = true;
  }
};

const refcommodityInfo = ref(null);

const emitGoodInfo = (info) => {
  if (action.value == "add" && fromtype.value !== "dcs") {
    const params = {
      ...info,
      merchantId: Number(merchantId.value),
    };
    goodsMerchantAdmin.add(params).then(({ data }) => {
      message.success("商品添加成功");
      router.push({
        name: "merchantDetails",
        query: { from: "newGoods", id: merchantId.value },
        //
      });
    });
  }
  if (action.value !== "add" && merchantId.value) {
    const params = {
      ...info,
      merchantId: Number(merchantId.value),
      id: goodsId.value,
    };
    goodsMerchantAdmin.update(params).then((res) => {
      message.success("商品编辑成功");
      console.log(refcommodityInfo.value, "refcommodityInfo.value");
      refcommodityInfo.value.changestate();
    });
  }
  if (action.value === "add" && fromtype.value === "dcs") {
    const params = {
      ...info,
    };
    goodsDcsAdmin.addDcsGoods(params).then((res) => {
      message.success("商品添加成功");
      router.push({
        name: "commodityList",
        query: { id: brandId.value, type: "1" },
      });
    });
  }
  if (action.value !== "add" && fromtype.value === "dcs" && !merchantId.value) {
    const params = {
      ...info,
      id: goodsId.value,
      brandId: brandId.value,
    };
    goodsDcsAdmin.updateGoods(params).then((res) => {
      message.success("商品编辑成功");
      refcommodityInfo.value.changestate();
    });
  }
};

const toback = () => {
  //  router.back()

  const {
    options: {
      history: {
        state: { back = "/" },
      },
    },
  } = router;
  if (back.indexOf("/merchantManagement/details") !== -1) {
    console.log(back + "&from=newGoods");
    const temp = back + "&from=newGoods";
    let theRequest = new Object();
    if (temp.indexOf("?") != -1) {
      var str = temp.split("?")[1];
      var strs = str.split("&");
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
      }
    }
    delete theRequest.keyword;
    console.log("theRequest", "aaaaaaaa");
    router.push({ name: "merchantDetails", query: { ...theRequest } });
  } else {
    router.back();
  }
};
</script>
<style scoped lang="less">
.index {
  box-sizing: border-box;
  padding: 28px 0 0 20px;
  &-content {
    height: 100%;
    background: #ffffff;
    &-return {
      padding: 20px 0 18px 32px;
      border-bottom: 1px solid #eeeeee;
    }
  }
}
</style>
