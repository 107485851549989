// @ts-ignore
/* eslint-disable */
import request from '@/request/request';

/** 修改折扣 PUT /admin/api-v1/goods-discount-dcs/${param0} */
export async function updateDiscount(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsDiscountDcsAdminUpdateDiscountParams,
  body: API.PutDiscountRequest,
  options?: { [key: string]: any },
) {
  const { goodsDiscountId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/goods-discount-dcs/${param0}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 设置品牌折扣 PUT /admin/api-v1/goods-discount-dcs/brand-discount */
export async function updateBrandDiscount(
  body: API.PutBrandDiscountRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/goods-discount-dcs/brand-discount', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除折扣 DELETE /admin/api-v1/goods-discount-dcs/del/${param0} */
export async function del(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsDiscountDcsAdminDelParams,
  options?: { [key: string]: any },
) {
  const { discountId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/goods-discount-dcs/del/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 全局折扣 GET /admin/api-v1/goods-discount-dcs/global */
export async function globalDiscount(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsDiscountDcsAdminGlobalDiscountParams,
  options?: { [key: string]: any },
) {
  return request<API.RInteger>('/admin/api-v1/goods-discount-dcs/global', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 设置全局折扣 PUT /admin/api-v1/goods-discount-dcs/global */
export async function updateGlobalDiscount(
  body: API.PutGlobalDiscountRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/goods-discount-dcs/global', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 设置商户品牌折扣 PUT /admin/api-v1/goods-discount-dcs/merchant-brand-discount */
export async function updateBrandDiscount_2(
  body: API.PutMerchantBrandDiscountRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/goods-discount-dcs/merchant-brand-discount', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 设置商户商品折扣 PUT /admin/api-v1/goods-discount-dcs/merchant-goods-discount */
export async function updateMerchantGoodsDiscount(
  body: API.PutGoodsDiscountRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/goods-discount-dcs/merchant-goods-discount', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 折扣分页列表 GET /admin/api-v1/goods-discount-dcs/page */
export async function page(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsDiscountDcsAdminPageParams,
  options?: { [key: string]: any },
) {
  return request<API.RIPageGoodsDiscountPageResponse>('/admin/api-v1/goods-discount-dcs/page', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
