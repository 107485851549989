// @ts-ignore
/* eslint-disable */
import request from '@/request/request';

/** 发货-查询物流轨迹 GET /admin/api-v1/dcs-order/${param0}/logistic-track */
export async function getLogisticTrack(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.DcsOrderAdminGetLogisticTrackParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.ROrderDeliveryLogisticTrackResponse>(
    `/admin/api-v1/dcs-order/${param0}/logistic-track`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 修改商品单价 POST /admin/api-v1/dcs-order/${param0}/price */
export async function updatePrice(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.DcsOrderAdminUpdatePriceParams,
  body: API.DcsOrderUpdatePriceRequest[],
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/dcs-order/${param0}/price`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 确认收款 POST /admin/api-v1/dcs-order/confirm-payment */
export async function confirmPayment(
  body: API.DcsOrderConfirmPaymentRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/dcs-order/confirm-payment', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 发货规格列表 GET /admin/api-v1/dcs-order/delivery-spec-list/${param0} */
export async function deliveryGoodsList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.DcsOrderAdminDeliveryGoodsListParams,
  options?: { [key: string]: any },
) {
  const { dcsOrderId: param0, ...queryParams } = params;
  return request<API.RListDcsOrderDeliveryGoodsResponse>(
    `/admin/api-v1/dcs-order/delivery-spec-list/${param0}`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 详情 GET /admin/api-v1/dcs-order/details/${param0} */
export async function details(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.DcsOrderAdminDetailsParams,
  options?: { [key: string]: any },
) {
  const { dcsOrderId: param0, ...queryParams } = params;
  return request<API.RDcsOrderDetailsResponse>(`/admin/api-v1/dcs-order/details/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 分页列表 GET /admin/api-v1/dcs-order/page */
export async function page(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.DcsOrderAdminPageParams,
  options?: { [key: string]: any },
) {
  return request<API.RIPageDcsOrderPageResponse>('/admin/api-v1/dcs-order/page', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 发货 POST /admin/api-v1/dcs-order/upload-url/delivery */
export async function delivery(
  body: API.DcsOrderDeliveryRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/dcs-order/upload-url/delivery', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 确认收款凭证上传地址 GET /admin/api-v1/dcs-order/upload-url/payment-voucher */
export async function getPaymentVoucherUploadUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.DcsOrderAdminGetPaymentVoucherUploadUrlParams,
  options?: { [key: string]: any },
) {
  return request<API.RPresignedUrlResponse>('/admin/api-v1/dcs-order/upload-url/payment-voucher', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
