// @ts-ignore
/* eslint-disable */
import request from '@/request/request';

/** 品牌列表 GET /admin/api-v1/goods-brand-merchant/brands */
export async function brands(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsBrandMerchantAdminBrandsParams,
  options?: { [key: string]: any },
) {
  return request<API.RCollectionGoodsBrandPageResponse>(
    '/admin/api-v1/goods-brand-merchant/brands',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 根据品牌控制授权商品上下架 PUT /admin/api-v1/goods-brand-merchant/up-down */
export async function upDown(
  body: API.PutGoodsBrandUpDownRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/goods-brand-merchant/up-down', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
