import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";





import Antd from 'ant-design-vue';
import 'antd/dist/antd.css';
import '../src/assets/css/my-ant.css'
const token = localStorage.getItem("token");

const Vue:any = createApp(App)

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {

    if (!token && to.name !== 'login') {
        //目前还没登录页
        // next({ name: 'login' })
        next()

    } else {
        next()
    } 
})


Vue.use(store).use(router).use(Antd).mount("#app");