// @ts-ignore
/* eslint-disable */
import request from '@/request/request';

/** 详情 GET /admin/api-v1/enquiry-order-dcs/${param0} */
export async function details(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OrderEnquiryOrderDcsAdminDetailsParams,
  options?: { [key: string]: any },
) {
  const { enquiryOrderId: param0, ...queryParams } = params;
  return request<API.ROrderEnquiryDetailsResponse>(`/admin/api-v1/enquiry-order-dcs/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 新增备注 POST /admin/api-v1/enquiry-order-dcs/${param0}/remark */
export async function addMerchantRemark(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OrderEnquiryOrderDcsAdminAddMerchantRemarkParams,
  body: API.SaveUserRemarkRequest,
  options?: { [key: string]: any },
) {
  const { enquiryOrderId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/enquiry-order-dcs/${param0}/remark`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 关闭 PUT /admin/api-v1/enquiry-order-dcs/close/${param0} */
export async function close(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OrderEnquiryOrderDcsAdminCloseParams,
  options?: { [key: string]: any },
) {
  const { enquiryOrderId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/enquiry-order-dcs/close/${param0}`, {
    method: 'PUT',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 列表 GET /admin/api-v1/enquiry-order-dcs/page */
export async function page(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OrderEnquiryOrderDcsAdminPageParams,
  options?: { [key: string]: any },
) {
  return request<API.RIPageOrderEnquiryPageResponse>('/admin/api-v1/enquiry-order-dcs/page', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 报价 PUT /admin/api-v1/enquiry-order-dcs/quote/${param0} */
export async function quote(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OrderEnquiryOrderDcsAdminQuoteParams,
  body: API.PutQuotedPriceEnquiryOrderRequest,
  options?: { [key: string]: any },
) {
  const { enquiryOrderId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/enquiry-order-dcs/quote/${param0}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 反馈 PUT /admin/api-v1/enquiry-order-dcs/usr/feedback/${param0} */
export async function feedbackUsr(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.OrderEnquiryOrderDcsAdminFeedbackUsrParams,
  body: API.PutOrderEnquiryFeedbackRequest,
  options?: { [key: string]: any },
) {
  const { enquiryOrderId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/enquiry-order-dcs/usr/feedback/${param0}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}
