// @ts-ignore
/* eslint-disable */
import request from '@/request/request';

/** 添加商户 POST /admin/api-v1/merchant/add */
export async function add(body: API.AddMerchantRequest, options?: { [key: string]: any }) {
  return request<API.RVoid>('/admin/api-v1/merchant/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 营业执照上传地址 GET /admin/api-v1/merchant/get-license-upload-url */
export async function getLicenseUploadUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAdminGetLicenseUploadUrlParams,
  options?: { [key: string]: any },
) {
  return request<API.RPresignedUrlResponse>('/admin/api-v1/merchant/get-license-upload-url', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 公司Logo上传地址 GET /admin/api-v1/merchant/get-logo-upload-url */
export async function getLogoUploadUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAdminGetLogoUploadUrlParams,
  options?: { [key: string]: any },
) {
  return request<API.RPresignedUrlResponse>('/admin/api-v1/merchant/get-logo-upload-url', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取指定商户信息 GET /admin/api-v1/merchant/get/${param0} */
export async function get(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAdminGetParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.RMerchantResponse>(`/admin/api-v1/merchant/get/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 商户的营业执照信息 GET /admin/api-v1/merchant/get/${param0}/license-info */
export async function getLicenseInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAdminGetLicenseInfoParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.RMerchantLicenseInfoResponse>(
    `/admin/api-v1/merchant/get/${param0}/license-info`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 商户的简单信息列表 GET /admin/api-v1/merchant/list/simple-info */
export async function listMerchantLicenseInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAdminListMerchantLicenseInfoParams,
  options?: { [key: string]: any },
) {
  return request<API.RListMerchantSimpleInfoResponse>('/admin/api-v1/merchant/list/simple-info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 商户列表 GET /admin/api-v1/merchant/page */
export async function page(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantAdminPageParams,
  options?: { [key: string]: any },
) {
  return request<API.RIPagePageMerchantResponse>('/admin/api-v1/merchant/page', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 更新商户 PUT /admin/api-v1/merchant/update */
export async function update(body: API.UpdateMerchantRequest, options?: { [key: string]: any }) {
  return request<API.RVoid>('/admin/api-v1/merchant/update', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 【启用|禁用】商户 PUT /admin/api-v1/merchant/update-status */
export async function updateStatus(
  body: API.UpdateMerchantStatusRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/merchant/update-status', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
