// @ts-ignore
/* eslint-disable */
import request from '@/request/request';

/** 添加 POST /admin/api-v1/dcs-user/add */
export async function add(body: API.AddDcsUserRequest, options?: { [key: string]: any }) {
  return request<API.RVoid>('/admin/api-v1/dcs-user/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 DELETE /admin/api-v1/dcs-user/delete/${param0} */
export async function deleteUsingDELETE(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.DcsUserAdminDeleteUsingDELETEParams,
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/dcs-user/delete/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 角色列表 GET /admin/api-v1/dcs-user/list-role */
export async function role(options?: { [key: string]: any }) {
  return request<API.RSetUserRoleResponse>('/admin/api-v1/dcs-user/list-role', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 个人信息 GET /admin/api-v1/dcs-user/owner-info */
export async function ownerInfo(options?: { [key: string]: any }) {
  return request<API.RDcsUserResponse>('/admin/api-v1/dcs-user/owner-info', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 分页查询 GET /admin/api-v1/dcs-user/page */
export async function page(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.DcsUserAdminPageParams,
  options?: { [key: string]: any },
) {
  return request<API.RIPageDcsUserResponse>('/admin/api-v1/dcs-user/page', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 更新 PUT /admin/api-v1/dcs-user/update */
export async function update(body: API.UpdateDcsUserRequest, options?: { [key: string]: any }) {
  return request<API.RVoid>('/admin/api-v1/dcs-user/update', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 用户基础信息 GET /admin/api-v1/dcs-user/userinfo */
export async function userinfo(options?: { [key: string]: any }) {
  return request<API.RUserinfoResponse>('/admin/api-v1/dcs-user/userinfo', {
    method: 'GET',
    ...(options || {}),
  });
}
