// @ts-ignore
/* eslint-disable */
import request from '@/request/request';

/** 支付配置-获取对公转账信息 GET /admin/api-v1/merchant-pay/${param0}/bank-transfer */
export async function getBankTransfer(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantPayAdminGetBankTransferParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RBankTransferPayResponse>(
    `/admin/api-v1/merchant-pay/${param0}/bank-transfer`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 支付配置-保存对公转账信息 POST /admin/api-v1/merchant-pay/${param0}/bank-transfer */
export async function saveBankTransfer(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantPayAdminSaveBankTransferParams,
  body: API.SaveBankTransferPayForMerchantRequest,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/merchant-pay/${param0}/bank-transfer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 支付配置-微信支付-获取p12证书上传的 URL(私有) GET /admin/api-v1/merchant-pay/${param0}/get-key-cert-upload-url */
export async function getKeyCertUploadUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantPayAdminGetKeyCertUploadUrlParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RPresignedUrlResponse>(
    `/admin/api-v1/merchant-pay/${param0}/get-key-cert-upload-url`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 支付配置-微信支付-信息 GET /admin/api-v1/merchant-pay/${param0}/wx */
export async function getWxPay(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantPayAdminGetWxPayParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RWxPayResponse>(`/admin/api-v1/merchant-pay/${param0}/wx`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 支付配置-微信支付-设置 POST /admin/api-v1/merchant-pay/${param0}/wx */
export async function saveWxPay(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.MerchantPayAdminSaveWxPayParams,
  body: API.MerchantWxPaySaveRequest,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/merchant-pay/${param0}/wx`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}
