import { RouteView } from "@/layouts";

import ManageStatic from "@/views/accessStatis/manageStatic.vue";
import MerchantStatis from "@/views/accessStatis/merchantStatis.vue";
export default {
  path: "/accessStatis",
  name: "",
  component: RouteView,
  meta: {
    title: "推广统计",
    isupperLevels: true,
  },
  children: [
    {
      path: "/accessStatis/manageStatic",
      name: "manageStatic",
      // component: cateList,
      component: ManageStatic,
      meta: {
        title: "商户统计",
        inMenu: false,
        isupperLevels: true,
      },
    },
    {
      path: "/accessStatis/merchantStatis",
      name: "merchantStatis",
      // component: cateList,
      component: MerchantStatis,
      meta: {
        title: "商品统计",
        inMenu: false,
        isupperLevels: true,
      },
    },
  ],
};
