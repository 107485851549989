// @ts-ignore
/* eslint-disable */
import request from '@/request/request';

/** 删除商品 DELETE /admin/api-v1/dcs-goods/${param0} */
export async function del(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsDcsAdminDelParams,
  options?: { [key: string]: any },
) {
  const { goodsId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/dcs-goods/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 新增商品 POST /admin/api-v1/dcs-goods/add */
export async function addDcsGoods(body: API.SaveGoodsMap, options?: { [key: string]: any }) {
  return request<API.RVoid>('/admin/api-v1/dcs-goods/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 检测证书上传地址 GET /admin/api-v1/dcs-goods/cert-upload-url */
export async function certUploadUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsDcsAdminCertUploadUrlParams,
  options?: { [key: string]: any },
) {
  return request<API.RPresignedUrlResponse>('/admin/api-v1/dcs-goods/cert-upload-url', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 图库 GET /admin/api-v1/dcs-goods/cover-images */
export async function coverImages(options?: { [key: string]: any }) {
  return request<API.RCollectionMerchantGoodsCoverImagesResponse>(
    '/admin/api-v1/dcs-goods/cover-images',
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}

/** 分页列表 GET /admin/api-v1/dcs-goods/goods-page */
export async function page(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsDcsAdminPageParams,
  options?: { [key: string]: any },
) {
  return request<API.RIPageGoodsListResponse>('/admin/api-v1/dcs-goods/goods-page', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 配图上传地址 GET /admin/api-v1/dcs-goods/img-upload-url */
export async function imgUploadUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsDcsAdminImgUploadUrlParams,
  options?: { [key: string]: any },
) {
  return request<API.RPresignedUrlResponse>('/admin/api-v1/dcs-goods/img-upload-url', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 富文本图片上传地址 GET /admin/api-v1/dcs-goods/rich-text-resource-upload-url */
export async function getRichTextResourceUploadUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsDcsAdminGetRichTextResourceUploadUrlParams,
  options?: { [key: string]: any },
) {
  return request<API.RPresignedUrlResponse>(
    '/admin/api-v1/dcs-goods/rich-text-resource-upload-url',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 上下架商品[根据商品ID] PUT /admin/api-v1/dcs-goods/up-down */
export async function upDownDcs(
  body: API.PutGoodsUpDownDcsRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/dcs-goods/up-down', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 上下架商品[批量] PUT /admin/api-v1/dcs-goods/up-down-batch */
export async function upDownBatchDcs(
  body: API.PutDcsGoodsUpDownBatchRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/dcs-goods/up-down-batch', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改商品 PUT /admin/api-v1/dcs-goods/update */
export async function updateGoods(body: API.SaveGoodsMap, options?: { [key: string]: any }) {
  return request<API.RVoid>('/admin/api-v1/dcs-goods/update', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
