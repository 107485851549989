import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const dcmMenu = () => import("@/layouts/dcmMenu.vue");
const login = () => import("@/views/login/loginPage.vue");
import merchant from "./modules/merchant";
import commoditymanagement from "./modules/commoditymanagement";
import classificationManagement from "./modules/classificationManagement";
import brandManage from "./modules/brandManage";
import systemSettings from "./modules/systemSettings";
import userManage from "./modules/userManage";
import orderCenter from "./modules/orderCenter";
import accessStatis from "./modules/accessStatis";
import inquiryCenter from "./modules/inquiryCenter";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: dcmMenu,
    children: [
      merchant,
      commoditymanagement,
      classificationManagement,
      systemSettings,
      userManage,
      orderCenter,
      inquiryCenter,
      accessStatis,
    ],
    // children: [
    //   merchant,
    //   commoditymanagement,
    //   classificationManagement,
    //   systemSettings,
    // ],
    meta: {
      title: "DCS管理",
    },
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      title: "登录",
      inMenu: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
