// @ts-ignore
/* eslint-disable */
import request from '@/request/request';

/** 新增 POST /admin/api-v1/goods-category-merchant/${param0} */
export async function add(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsCategoryMerchantAdminAddParams,
  body: API.SaveCategoryMerchantRequest,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/goods-category-merchant/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /admin/api-v1/goods-category-merchant/${param0}/${param1} */
export async function details(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsCategoryMerchantAdminDetailsParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, categoryId: param1, ...queryParams } = params;
  return request<API.RGoodsCategoryMerchantDetailsRsp>(
    `/admin/api-v1/goods-category-merchant/${param0}/${param1}`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 修改 PUT /admin/api-v1/goods-category-merchant/${param0}/${param1} */
export async function update(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsCategoryMerchantAdminUpdateParams,
  body: API.SaveCategoryMerchantRequest,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, categoryId: param1, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/goods-category-merchant/${param0}/${param1}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 删除 DELETE /admin/api-v1/goods-category-merchant/${param0}/${param1} */
export async function deleteUsingDELETE(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsCategoryMerchantAdminDeleteUsingDELETEParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, categoryId: param1, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/goods-category-merchant/${param0}/${param1}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 排序 PUT /admin/api-v1/goods-category-merchant/${param0}/sort */
export async function sort(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsCategoryMerchantAdminSortParams,
  body: number[],
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/goods-category-merchant/${param0}/sort`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /admin/api-v1/goods-category-merchant/${param0}/tree */
export async function tree(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsCategoryMerchantAdminTreeParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RCollectionGoodsCategoryTreeOfTotalRsp>(
    `/admin/api-v1/goods-category-merchant/${param0}/tree`,
    {
      method: 'GET',
      params: {
        ...queryParams,
      },
      ...(options || {}),
    },
  );
}
