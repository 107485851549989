import { RouteView } from "@/layouts";
import merchantManagement from "@/views/dcsManage/merchantManagement.vue";
import merchantDetails from "@/views/dcsManage/merchantDetails/merchantDetails.vue";
import addMerchant from "@/views/dcsManage/merchantDetails/addMerchant.vue";
import merchantCommodity from "@/views/dcsManage/merchantDetails/goodDetail.vue";
export default {
  path: "/merchantManagement",
  name: "",
  component: RouteView,
  meta: {
    title: "商户管理",
    isupperLevels:true

  },
  children: [
    {
      path: "/merchantManagement/merchantList",
      name: "merchantManagement",
      component: merchantManagement,
      meta: {
        title: "商户列表",
        inMenu: false,
        isupperLevels:true
      },
    },
    {
      path: "/merchantManagement/details",
      name: "merchantDetails",
      component: merchantDetails,
      meta: {
        title: "商户详情",
        inMenu: false,
      },
    },
    {
      path: "/merchantManagement/addMerchant",
      name: "addMerchant",
      component: addMerchant,
      meta: {
        title: "新增商户",
        inMenu: false,
      },
    },
    {
      path: "/merchantManagement/merchantCommodity",
      name: "merchantCommodity",
      component: merchantCommodity,
      meta: {
        title: "商品管理",
        inMenu: false,
      },
    },
  ],
};
