
import { RouteView } from "@/layouts";
import userManage from "@/views/orderCenter/orderCenter.vue";
import orderDetails from "@/views/orderCenter/orderDetails.vue";

export default {
  path: "/orderCenter",
  name: "",
  component: RouteView,
  meta: {
    title: "订单中心",
    isupperLevels:true

  },
  children: [
    {
      path: "/orderCenter/list",
      name: "orderCenterList",
      component: userManage,
      meta: {
        title: "订单列表",
        inMenu: false,
        isupperLevels:true
      },
    },
    {
        path: "/orderCenter/details",
        name: "orderCenterDetails",
        component: orderDetails,
        meta: {
          title: "订单详情",
          inMenu: false,
          isupperLevels:true
        },
      },
  ],
};
