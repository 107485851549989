// @ts-ignore
/* eslint-disable */
import request from '@/request/request';

/** 修改 PUT /admin/api-v1/goods-category-dcs/${param0} */
export async function update(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsCategoryDcsAdminUpdateParams,
  body: API.SaveCategoryDcsRequest,
  options?: { [key: string]: any },
) {
  const { categoryId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/goods-category-dcs/${param0}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 删除 DELETE /admin/api-v1/goods-category-dcs/${param0} */
export async function deleteUsingDELETE(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsCategoryDcsAdminDeleteUsingDELETEParams,
  options?: { [key: string]: any },
) {
  const { categoryId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/goods-category-dcs/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 详情 GET /admin/api-v1/goods-category-dcs/${param0} */
export async function details(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsCategoryDcsAdminDetailsParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.RGoodsCategoryDcsDetailsRsp>(`/admin/api-v1/goods-category-dcs/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 新增 POST /admin/api-v1/goods-category-dcs/add */
export async function add(body: API.SaveCategoryDcsRequest, options?: { [key: string]: any }) {
  return request<API.RVoid>('/admin/api-v1/goods-category-dcs/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 同步所有商户 POST /admin/api-v1/goods-category-dcs/sync-all-merchant */
export async function syncAllMerchant(options?: { [key: string]: any }) {
  return request<API.RVoid>('/admin/api-v1/goods-category-dcs/sync-all-merchant', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 列表 GET /admin/api-v1/goods-category-dcs/tree */
export async function tree(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsCategoryDcsAdminTreeParams,
  options?: { [key: string]: any },
) {
  return request<API.RCollectionGoodsCategoryTreeOfTotalRsp>(
    '/admin/api-v1/goods-category-dcs/tree',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}
