// @ts-ignore
/* eslint-disable */
import request from '@/request/request';

/** 删除商品 DELETE /admin/api-v1/merchant-goods/${param0} */
export async function del(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsMerchantAdminDelParams,
  options?: { [key: string]: any },
) {
  const { goodsId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/merchant-goods/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 授权商品列表(筛选框) GET /admin/api-v1/merchant-goods/${param0}/authorized-goods-list */
export async function authorizedGoodsList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsMerchantAdminAuthorizedGoodsListParams,
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RCollectionGoodsAuthorizedListResponse>(
    `/admin/api-v1/merchant-goods/${param0}/authorized-goods-list`,
    {
      method: 'GET',
      params: {
        ...queryParams,
      },
      ...(options || {}),
    },
  );
}

/** 编辑详情 GET /admin/api-v1/merchant-goods/${param0}/edit-detail */
export async function goodsEditDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsMerchantAdminGoodsEditDetailParams,
  options?: { [key: string]: any },
) {
  const { goodsId: param0, ...queryParams } = params;
  return request<API.RListGoodsAttrMatcherResponse>(
    `/admin/api-v1/merchant-goods/${param0}/edit-detail`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 修改商品规格[dcs] PUT /admin/api-v1/merchant-goods/${param0}/update-dcs-goods-specs */
export async function updateDcs(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsMerchantAdminUpdateDcsParams,
  body: API.PutGoodsSpecPriceRequest[],
  options?: { [key: string]: any },
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<API.RVoid>(`/admin/api-v1/merchant-goods/${param0}/update-dcs-goods-specs`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 新增商户商品 POST /admin/api-v1/merchant-goods/add */
export async function add(body: API.SaveGoodsMap, options?: { [key: string]: any }) {
  return request<API.RVoid>('/admin/api-v1/merchant-goods/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 图库 GET /admin/api-v1/merchant-goods/cover-images */
export async function coverImages(options?: { [key: string]: any }) {
  return request<API.RCollectionMerchantGoodsCoverImagesResponse>(
    '/admin/api-v1/merchant-goods/cover-images',
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}

/** 商品统计 GET /admin/api-v1/merchant-goods/goods_count */
export async function goodsCount(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsMerchantAdminGoodsCountParams,
  options?: { [key: string]: any },
) {
  return request<API.RGoodsCountResponse>('/admin/api-v1/merchant-goods/goods_count', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 列表 GET /admin/api-v1/merchant-goods/page */
export async function goodsPage(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.GoodsMerchantAdminGoodsPageParams,
  options?: { [key: string]: any },
) {
  return request<API.RIPageMerchantGoodsPageResponse>('/admin/api-v1/merchant-goods/page', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 上下架商品 PUT /admin/api-v1/merchant-goods/up-down */
export async function upDownMerchant(
  body: API.PutMerchantGoodsUpDownRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/merchant-goods/up-down', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 商户-审核上下架商户商品 PUT /admin/api-v1/merchant-goods/up-down-audit */
export async function upDownAuditMerchant(
  body: API.PutMerchantGoodsUpDownRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/merchant-goods/up-down-audit', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 商户-批量上下架 PUT /admin/api-v1/merchant-goods/up-down-batch */
export async function upDownBatch(
  body: API.PutMerchantGoodsUpDownBatchRequest,
  options?: { [key: string]: any },
) {
  return request<API.RVoid>('/admin/api-v1/merchant-goods/up-down-batch', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改商品[自有] PUT /admin/api-v1/merchant-goods/update */
export async function update(body: API.SaveGoodsMap, options?: { [key: string]: any }) {
  return request<API.RVoid>('/admin/api-v1/merchant-goods/update', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
