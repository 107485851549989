import { createStore } from "vuex";
import state from './state'
import getters from './getters'
import loginStore from './modules/login'

console.log(loginStore,'loginStoreloginStoreloginStoreloginStoreloginStoreloginStoreloginStoreloginStore')

export default createStore({
  state,
  getters,
  mutations: {},
  actions: {},
  modules: {loginStore},
});
