
import { RouteView } from "@/layouts";

import cateList from "@/views/cateManage/commodityCategory.vue"

export default {
  path: "/cateManage",
  name: "",
  component: RouteView,
  meta: {
    title: "分类管理",
    isupperLevels:true
  },
  children: [
    {
      path: "/cateManage/list",
      name: "cateList",
      // component: cateList,
      component: cateList,
      meta: {
        title: "分类列表",
        inMenu: false,
        isupperLevels:true
      },
    },
   
  ],
};
