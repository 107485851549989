import Cookies from "js-cookie"

const loginStore = {
  state: {
    access_token: "",
  },
  mutations: {
    setAccessToken(state, val) {
      state.access_token = val;
      console.log(state,'statestatestatestate', state.access_token ,'valvalval',val)
      Cookies.set("access_token", val);
    },
    clearAccessToken(state) {
      state.access_token = "";
      Cookies.remove("access_token");
    },
    getAccessToken(state) {
      state.access_token = state.access_token || Cookies.get("access_token");
    },
  },
};
export default loginStore;
